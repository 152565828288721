<template >
  <div >
    <v-container class="pa-0 pa-sm-5">
      <v-carousel 
        ref="carousel"
        class="mt-0"
        vertical-delimiters 
        vertical 
        v-model="model"
        :continuous="continuous"
        :hide-delimiters="model === 10"
        delimiter-icon="mdi-circle-medium"
        height="80vh"
      >
      <!-- Removed v-touch from all carousel-items due to issues with mobile page reload 
        v-touch="{
          up: () => swipe('Up'),
          down: () => swipe('Down')
        }"
      -->
        <v-carousel-item>
          <v-sheet
            color="red"
            height="100%"
            tile
          >
            <v-row
              @wheel="onWheel"
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-col cols="9" offset="1" sm="9" offset-sm="1">
                <!-- <div class="text-h4"> -->
                  <!-- Sales Offer
                  <p class="text-body-2">In 8 quick steps</p> -->
                  <v-row align="center" class="">
                    <v-col cols="12" sm="6" class="d-flex flex-column justify-start">
                      <div class="text-h4">
                        Sales Offer
                        <p class="text-body-2">In 8 quick steps</p>
                      </div>
                      <v-btn :autofocus="model === 0" outlined @click="model++" width="150" :x-large="$vuetify.breakpoint.smAndUp" color="white">
                        <span class="white--text">Start</span>
                      </v-btn>
                    </v-col>
                    <v-col cols="6" class="d-none d-md-flex">
                      <v-icon id="intro-icon" size="350" >mdi-file-document-edit-outline</v-icon>
                    </v-col>
                  </v-row>
                <!-- </div> -->
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item>
          <v-sheet
            color="secondary"
            height="100%"
            tile
          >
            <v-row
              @wheel="onWheel"
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-col cols="9" offset="1" sm="9" offset-sm="1">
                <div class="text-h5">1. Property
                  <v-row class="mt-3">
                    <v-col cols="12" sm="6">
                      <SearchSalesInstructions
                        color="white"
                        v-if="!selectedProperty"
                        @selectedSalesInstruction="setProperty"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-row align="center">
                        <v-col cols="2">
                          <v-icon @click="selectedProperty = '' " class="mr-3" v-if="selectedProperty">mdi-close</v-icon>
                        </v-col>
                        <v-col>
                          <div class="text-subtitle-1 text-sm-h6 d-flex flex-wrap align-center">
                            <span>{{ selectedProperty.AddressNumber }} {{ selectedProperty.Address1 }}</span>
                          </div>
                          <span class="text-h4 text-sm-h3">{{ selectedProperty.Postcode }}</span>
                          <div class="mt-5 text-body-1" v-if="selectedProperty">
                            Guide price: 
                            <p class="text-h6">{{ selectedProperty.GuidePrice | filterPrice }}</p>
                            <!-- <p class="text-h5">£12,000,000.00</p> -->
                          </div>
                        </v-col>
                      </v-row>
                      <v-row justify="end">
                        <v-btn @click="model++" color="white" text>Next</v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>

        <v-carousel-item>
          <v-sheet
            color="primary"
            height="100%"
            tile
          >
            <v-row
              @wheel="onWheel"
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-col cols="9" offset="1" sm="9" offset-sm="1">
                <div class="text-h5">2. Buyer
                  <v-row class="mt-3">
                    <v-col cols="12" sm="6">
                      <SearchBuyer 
                        color="white"
                        v-if="!selectedPerson"
                        @selectedBuyer="setPerson"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-row align="center">
                        <v-col cols="2">
                          <v-icon @click="resetBuyer" class="mr-3" v-if="selectedPerson">mdi-close</v-icon>
                        </v-col>
                        <v-col class="text-h5 text-sm-h2">
                          <span class="text-subtitle-1 text-sm-h5"  v-if="selectedPerson.Title">{{ selectedPerson.Title}}</span>
                          {{ selectedPerson.Forename }}
                          {{ selectedPerson.Surname }}
                          <p v-if="selectedPerson.EmailAddress" class="mt-3 mb-0 text-body-1">{{ selectedPerson.EmailAddress }}</p>
                          <p v-if="selectedPerson.MobilePhone" class="mt-0 text-body-1">{{ selectedPerson.MobilePhone }}</p>
                        </v-col>
                      </v-row>
                      <v-row v-if="selectedPerson">
                        <v-col cols="2"></v-col>
                        <v-col>
                          <span class="text-h6 font-weight-light">Spouse or Partner associated with offer</span>
                          <v-row>
                            <v-col cols="12" sm="6">
                              <v-text-field
                                label="Full name"
                                v-model="associatedBuyer"
                              ></v-text-field>
                            </v-col>
                          </v-row>     
                        </v-col>

                      </v-row>
                      <v-row justify="end">
                        <v-btn @click="model++" color="white" text>Next</v-btn>
                      </v-row>  
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item>
          <v-sheet
            color="yellow darken-2"
            height="100%"
            tile
          >
            <v-row
              @wheel="onWheel"
              class="fill-height"
              align="center"
              justify="center"
            >
            <v-col cols="9" offset="1" sm="9" offset-sm="1">
              <div class="text-h5">3. Offer
                <v-row class="mt-3">
                  <v-col cols="10" sm="6">
                    <v-text-field
                      :autofocus="model === 3"
                      clearable
                      type="number"
                      v-model="offer"
                      label="Enter offer"
                      prepend-inner-icon="mdi-currency-gbp"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <span class="text-h4 text-sm-h2" v-if="offer"> {{ offer | filterPrice }}</span>
                  </v-col>
                </v-row>
                <v-row justify="end">
                  <v-btn @click="model++" color="white" text>Next</v-btn>
                </v-row>                  
              </div>
            </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item>
          <v-sheet
            color="green"
            height="100%"
            tile
          >
            <v-row
              @wheel="onWheel"
              class="fill-height"
              align="center"
              justify="center"
            >
            <v-col cols="9" offset="1" sm="9" offset-sm="1">
              <div class="text-h5">4. Deposit type
                <v-row class="mt-3">
                  <v-col cols="10" sm="6">
                    <v-select
                      :autofocus="model === 4"
                      v-model="depositType"
                      :items="listDepositTypes"
                      item-text="name"
                      item-value="value"
                      return-object=""
                      label="Select a type"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-row justify="end">
                      <v-btn @click="model++" color="white" text>Next</v-btn>
                    </v-row>   
                  </v-col>
                </v-row>
              </div>
            </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item>
          <v-sheet
            color="blue "
            height="100%"
            tile
          >
            <v-row
              @wheel="onWheel"
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-col cols="9" offset="1" sm="9" offset-sm="1">
                <div class="text-h5">5. Offers subject to:
                  <v-row class="mt-3">
                    <v-col cols="12" sm="8">

                      <div class="d-flex">
                        <v-switch color="white" v-model="SubjectToContract" inset label="Subject to contract"></v-switch> 
                        <v-icon v-if="SubjectToContract" class="ml-3" color="white">mdi-check</v-icon>
                      </div>
                      <div class="d-flex">
                        <v-switch color="white" v-model="SubjectToMortgage" inset label="Subject to mortgage"></v-switch> 
                        <v-icon v-if="SubjectToMortgage" class="ml-3" color="white">mdi-check</v-icon>
                      </div>
                      <div class="d-flex">
                        <v-switch color="white" v-model="SubjectToSurvey" inset label="Subject to survey"></v-switch>
                        <v-icon v-if="SubjectToSurvey" class="ml-3" color="white">mdi-check</v-icon>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row justify="end">
                    <v-btn @click="model++" color="white" text>Next</v-btn>
                  </v-row>   
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item>
          <v-sheet
            color="orange"
            height="100%"
            tile
          >
            <v-row
              @wheel="onWheel"
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-col cols="9" offset="1" sm="9" offset-sm="1">
                <div class="text-h5">6. Offer Conditions
                  <v-row class="mt-3">
                    <v-col cols="12" sm="10">
                      <v-textarea
                        :autofocus="model === 6"
                        outlined
                        class="text-sm-h6"
                        clearable
                        placeholder="...optional"
                        v-model="specialTerms"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row justify="end">
                    <v-btn @click="model++" color="white" text>Next</v-btn>
                  </v-row> 
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item>
          <v-sheet
            color="orange"
            height="100%"
            tile
          >
            <v-row
              @wheel="onWheel"
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-col cols="9" offset="1" sm="9" offset-sm="1">
                <div class="text-h5">7. Offer conditions relating to fixtures and fittings
                  <v-row class="mt-3">
                    <v-col cols="12" sm="10">
                      <v-textarea
                        :autofocus="model === 7"
                        clearable
                        outlined
                        class="text-sm-h6"
                        name="input-7-4"
                        placeholder="Fixtures and fittings are optional"
                        v-model="fixturesAndFittings"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row justify="end">
                    <v-btn @click="model++" color="white" text>Next</v-btn>
                  </v-row> 
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item>
          <v-sheet
            color="orange"
            height="100%"
            tile
          >
            <v-row
              @wheel="onWheel"
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-col cols="9" offset="1" sm="9" offset-sm="1">
                <div class="text-h5">8. Internal notes
                  <v-row class="mt-3">
                    <v-col cols="12" sm="10">
                      <v-textarea
                        ref="internalNotesText"
                        :autofocus="model === 8"
                        clearable
                        outlined
                        class="text-sm-h6"
                        name="input-7-4"
                        placeholder="...optional"
                        v-model="internalNotes"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row justify="end">
                    <v-btn @click="model++" color="white" text>Next</v-btn>
                  </v-row> 
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item
          v-touch="{
            up: () => swipe('Up'),
            down: () => swipe('Down')
          }"
        >
          <v-sheet
            color="blue"
            height="100%"
            tile
          >
            <v-row
              @wheel="onWheel"
              class="fill-height"
              align="center"
              justify="center"
            >
            <v-col cols="9" offset="1" sm="9" offset-sm="1">
              <div class="text-h4">
                AML Documents
                <p class="text-body-1">Max. 5mb</p>
                <div class="d-flex">
                  <v-switch color="white" v-model="FinancialVerified" inset label="Financial verified"></v-switch> 
                  <v-icon v-if="FinancialVerified" class="ml-3" color="white">mdi-check</v-icon>
                </div>
                <v-row class="mt-3">
                  <v-col cols="12" sm="8">
                    <v-file-input 
                      :autofocus="model === 10"
                      @change="uploadDocuments" 
                      small-chips 
                      outlined 
                      multiple 
                      label="Select multiple files for upload"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row v-if="attachedDocuments.length">
                  <div>
                    <v-chip
                      v-for="(doc, index) in attachedDocuments"
                      :key="doc.DocumentName"
                      class="ma-2"
                      close
                      color="white"
                      text-color="blue"
                      @click:close="removeDocument(index)"
                    >
                      <v-avatar left>
                        <v-icon>mdi-checkbox-marked-circle</v-icon>
                      </v-avatar>
                      {{ doc.DocumentName }}
                    </v-chip>
                  </div>
                </v-row>
                <p class="text-body-2" v-if="attachedDocuments.length">{{ attachmentsTotalSize.toFixed(1) }}Mb</p>
                <v-row justify="end">
                  <v-btn @click="model++" color="white" text>Next</v-btn>
                </v-row>
              </div>
            </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item>
          <v-sheet
            color="primary"
            height="100%"
            tile
          >
            <v-row
              @wheel="onWheel"
              class="fill-height"
              align="center"
              justify="center"
            >
            <v-col cols="9" offset="1" sm="9" offset-sm="1">
              <div class="text-h5">Summary
                <v-row class="mt-3">
                  <v-col cols="12" sm="10">
                    <div v-if="!selectedProperty" class="my-2 d-flex align-center">
                      <v-btn text @click="model = 1">
                        <v-icon color="white" left>mdi-alert</v-icon>
                        <span class="text-body-1 text-sm-h6 font-weight-bold white--text">Property details are required.</span>
                      </v-btn>
                    </div>
                    <div v-if="!selectedPerson" class="my-2 d-flex align-center">
                      <v-btn text @click="model = 2">
                        <v-icon color="white" left>mdi-alert</v-icon>
                        <span class="text-body-1 text-sm-h6 font-weight-bold white--text">Buyer details are required.</span>
                      </v-btn>
                    </div>
                    <div v-if="!offer" class="my-2 d-flex align-center">
                      <v-btn text @click="model = 3">
                        <v-icon color="white" left>mdi-alert</v-icon>
                        <span class="text-body-1 text-sm-h6 font-weight-bold white--text">Offer details are required.</span>
                      </v-btn>
                    </div>
                    <div v-if="!depositType" class="my-2 d-flex align-center">
                      <v-btn text @click="model = 4">
                        <v-icon color="white" left>mdi-alert</v-icon>
                        <span class="text-body-1 text-sm-h6 font-weight-bold white--text">Deposit details are required.</span>
                      </v-btn>
                    </div>
                    <div v-if="attachmentsTotalSize > 5" class="my-2 d-flex align-center">
                      <v-btn text @click="model = 9">
                        <v-icon color="white" left>mdi-alert</v-icon>
                        <span class="text-body-1 text-sm-h6 font-weight-bold white--text">Attached documents size exceeds 5mb.</span>
                      </v-btn>
                    </div>
                    <div v-if="allFieldsOK" class="text-body-1">
                      Offer of <span class="font-weight-bold">{{ offer | filterPrice }}</span> 
                      <br>
                      on <span class="font-weight-bold">{{selectedProperty.AddressNumber}} {{ selectedProperty.Address1}}</span> 
                      <br>
                      on behalf of <span class="font-weight-bold" v-if="selectedPerson.Title">{{ selectedPerson.Title}}</span> <span class="font-weight-bold">{{ selectedPerson.Forename }} {{ selectedPerson.Surname }}</span>
                      <br>
                      <p class="mt-3" v-if="attachedDocuments.length">{{attachedDocuments.length}} document/s attached.</p>
                    </div>
                    <v-btn class="mt-5" @loading="loading" v-if="allFieldsOK" @click="makeOffer" color="success">Submit pending offer</v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item
          v-if="offerSuccessful"
        >
          <v-sheet
            color="green"
            height="100%"
            tile
          >
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
            <v-col cols="9" offset="1" sm="9" offset-sm="1">
              <div class="text-h4 text-sm-h2">
                Offer submitted successfully.
                <v-row justify="start" class="mt-3">
                  <v-col cols="12">
                    <v-btn @click="startNewOffer" color="white" outlined>
                      <span>Enter new offer</span>
                      <v-icon small right>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item
          v-if="offerUnsuccessful"
        >
          <v-sheet
            color="red"
            height="100%"
            tile
          >
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
            <v-col cols="9" offset="1" sm="9" offset-sm="1">
              <div class="text-h4 text-sm-h2">
                Error submitting offer.
                <v-row justify="start" class=" mt-3">
                  <v-col class=" px-0" cols="12">
                    <p class="text-body-1 pl-3">{{ errorMsg }}. If the error persists, please contact support.</p>
                    <v-btn @click="startNewOffer" color="white" outlined>
                      <span>Enter new offer</span>
                      <v-icon small right>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>

        <!-- Back and forth btns -->
        <div id="buttons-wrapper" v-if="model !== 11">
          <v-row class="mt-3 mb-12" justify="end">
            <v-col 
              class="d-flex align-center justify-space-around" 
              cols="8" offset="2" 
              sm="4" offset-sm="8"
            >
              <v-btn small @click="model--" color="white" text>
                <v-icon >mdi-arrow-up</v-icon>
              </v-btn>
              <span class="white--text">Page {{ model + 1 }} of 11</span>
              <v-btn v-if="model !== 9" small @click="model++" color="white" text>
                <v-icon >mdi-arrow-down</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>

      </v-carousel>

    <!-- Back and forth BTNs -->
    <!-- <v-row class="mt-3 mb-12">
      <v-col class="d-flex align-center justify-space-between" cols="12" sm="4" offset-sm="8">
        <v-btn @click="model--" color="success" outlined>
          <v-icon >mdi-arrow-up</v-icon>
        </v-btn>
        Page {{ model + 1 }} of 5
        <v-btn @click="model++" color="success" outlined>
          <v-icon >mdi-arrow-down</v-icon>
        </v-btn>
      </v-col>
    </v-row> -->

    </v-container>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import SearchSalesInstructions from '@/components/Searches/SearchSalesInstructions.vue';
import SearchBuyer from '@/components/Searches/SearchBuyer.vue';

export default {
  name: 'sales-offer',
  components: {
    SearchSalesInstructions,
    SearchBuyer
  },
  data () {
    return {
      loading: false,
      offerSuccessful: false,
      offerUnsuccessful: false,
      errorMsg: '',
      offer: '',
      selectedProperty: '',
      selectedPerson: '',
      associatedBuyer: '',
      continuous: false,
      FinancialVerified: false,
      SubjectToContract: false,
      SubjectToMortgage: false,
      SubjectToSurvey: false,
      listDepositTypes: [
        {
          name: 'Cash',
          value: 'Cash',
        },
        {
          name: 'Gifted deposit',
          value: 'GiftedDeposit'
        },
        {
          name: 'Help to buy scheme',
          value: 'HelpToBuyScheme'
        },
        {
          name: 'Help to buy ISA',
          value: 'HelpToBuyISA'
        },
        {
          name: 'Other',
          value: 'Other'
        }
      ],
      depositType: '',
      specialTerms: '',
      fixturesAndFittings: '',
      internalNotes: '',
      model: 0,
      attachedDocuments: []
    }
  },
  created() {
    this.$store.commit("EXPIRE_LOGIN");
    if(this.$store.state.user !== ''){
      if(this.$store.state.OffersApplications.selectedBuyer) {
        this.selectedPerson = this.$store.state.OffersApplications.selectedBuyer
      }
    }
    else{
      this.$router.push('/');
    }

  },
  beforeDestroy() {
    this.$store.commit('OffersApplications/CLEAR_SELECTED_BUYER')
  },
  computed: {
    allFieldsOK() {
      if(this.selectedProperty && this.selectedPerson && this.offer && this.depositType && this.attachmentsTotalSize < 5) { return true }
      return false
    },
    attachmentsTotalSize() {
      if(this.attachedDocuments.length) {
        const totalSize = this.attachedDocuments.map(item => item.Size).reduce( (acc, current) => {
          return acc + current
        })
        const sizeInMb = (totalSize / 1000000)
        return sizeInMb
      }
      return 0
    }
  },
  methods: {
    resetBuyer() {
      this.selectedPerson = ''
      this.associatedBuyer = ''
    },
    startNewOffer() {
      this.model = 0
      this.offerSuccessful = false
      this.offerUnsuccessful = false
      this.errorMsg = ''

      this.selectedProperty = ''
      this.selectedPerson = ''
      this.offer = ''
      this.depositType = ''
      this.FinancialVerified = false
      this.SubjectToContract = false
      this.SubjectToMortgage = false
      this.SubjectToSurvey = false
      this.specialTerms = ''
      this.fixturesAndFittings = ''
      this.internalNotes = ''
      this.attachedDocuments = []
    },
    makeOffer() {
      this.loading = true
      const offerObject = {
        SalesInstructionID: this.selectedProperty.OID,
        BuyerApplicantID: this.selectedPerson.OID,
        OfferAmount: parseInt(this.offer).toFixed(2),
        DepositType: this.depositType.value,
        FinancialVerified: this.FinancialVerified,
        SubjectToContract: this.SubjectToContract,
        SubjectToMortgage: this.SubjectToMortgage,
        SubjectToSurvey: this.SubjectToSurvey,
        FixturesAndFittings: this.fixturesAndFittings,
        SpecialTerms: this.specialTerms,
        InternalNotes: this.internalNotes,
        Documents: this.attachedDocuments
      }
      this.$store.dispatch('OffersApplications/submitSalesOffer', offerObject)
      .then( () => {
        this.loading = false
        this.offerSuccessful = true
        this.model++
      })
      .catch(err => {
        this.offerUnsuccessful = true
        this.model++
        this.loading = false
        this.errorMsg = err
      })
    },
    setProperty(property) {
      const proptertyID = property.OID
      this.$store.dispatch('salesInstructionsDetails', proptertyID)
      .then(res => {
        this.selectedProperty = res.data
      })
      
    },
    setPerson(person) {
      const personID = person.OID
      this.$store.dispatch('getBuyerDetails', personID)
      .then( res => {
        this.selectedPerson = {...res.data, OID: personID}
      })
    },
    onWheel: debounce(function(e) {
      if(e.deltaY > 0) {
        if(this.model < 9) {
          this.model++
        }
      } else {
        this.model--
      }
      }, 160, {
        'leading': true,
        'trailing': false
    }),
    swipe(direction) { //currenlty not in use due to issues on mobile (page reloading on up)
      if(direction === "Up") {
        this.model++
      } else {
        this.model--
      }
    },
    // Document uploads
    uploadDocuments(files) {
      // console.log(files)
      files.forEach( file => {
        this.createBase64String(file)
      })
    },
    createBase64String(fileObject) {
      // console.log(fileObject)
      const reader = new FileReader();

      reader.onload = () => {
        // console.log(reader.result);
        const baser64String = reader.result.split("base64,")[1]
        const documentObject = {
          DocumentName: fileObject.name,
          MimeType: fileObject.type,
          Contents: baser64String,
          Size: fileObject.size
        }
        this.attachedDocuments.push(documentObject)
      };

      reader.readAsDataURL(fileObject)
    },
    removeDocument(index) {
      this.attachedDocuments.splice(index, 1)
    },
  },
  filters: {
    filterPrice(val) {
      const numberVal = parseInt(val)
      // return val.toLocaleString('en-UK', {style: 'currency', currency: 'GBP'}) 
      // return '£' + numberVal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      return '£' + numberVal.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
  },
  watch: {
    associatedBuyer() {
      this.internalNotes = `Associated buyer: ${this.associatedBuyer}`
    } 
  }
}
</script>

<style>
#buttons-wrapper {
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0;
  right: 10px;
  display: flex;
}

#intro-icon {
  opacity: 20%;
}

</style>